.pricing {
  /* min-height: calc(100vh - 135px); */
  width: calc(100vw - 30%);
  margin: 64px auto 0 auto; 
  padding-top: 60px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.pricing h1 {
  color: white;
  font-size: 50px;
  font-weight: bolder;
}

.pricing .priceToggler {
  display: flex;
  margin: 45px auto 70px;
  background-color: #f3f3f3;
  border-radius: 20px;
  color: #bbbbbb;
  height: 70px;
  width: 300px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

.pricing .priceToggler .menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 70px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pricing .priceToggler .menu:first-child {
  border-radius: 20px 0 0 20px;
}

.pricing .priceToggler .menu:last-child {
  border-radius: 0 20px 20px 0;
}

.pricing .priceToggler .menu.active {
  background-color: #3fd13c;
  color: white;
  scale: 1.02;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
}

.pricing .priceContent {
  font-size: 24px;
  color: white;
}

.pricing .priceContent .content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.pricing .priceContent .content h2 {
  margin-bottom: 35px;
  font-weight: bold;
  /* font-size: 28px; */
  font-size: 36px;
  text-align: center;
}

.pricing .priceContent .content .plans-container {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.pricing .priceContent .content .plans {
  border-radius: 15px;
  border: 3px solid white;
  width: 400px;
  box-sizing: border-box;
  padding: 30px;
  text-align: left;
  height: fit-content;
}
.pricing .priceContent .content .plans:last-child:hover {
  border-color: #ffc700;
  cursor: pointer;
  transition: .5s;
  h3 {
    color: #ffc700;
    transition: .5s;
  }
}

.pricing .priceContent .content .plans .price {
  text-align: right;
}

.pricing .priceContent .content .plans .price .discount {
  text-align: center;
  transform: translate(60px, 15px);
  margin: 30px 0;
}

.pricing .priceContent .content .plans h3 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 25px;
}

.pricing .priceContent .content .plans h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.pricing .priceContent .content .plans p {
  font-size: 11px;
  font-weight: bold;
  color: #ffc700;
  margin-bottom: 5px;
}

.pricing .priceContent .content .plans .discount h5 {
  font-size: 20px;
  font-weight: bold;
  color: #cecece;
  text-decoration: line-through;
}

.pricing .priceContent .content .plans .price h4 {
  margin: 0;
}

.check-icon {
  color: white;
  margin-right: 15px;
  font-size: 20px;
}

.active-badge {
  display: flex;
  background-color: #28a745;
  color: white;
  height: 30px;
  width: 110px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 20px;
}


/* Media Queries */
@media (max-width: 768px) {
  .pricing {
    width: 90%;
    margin: 40px auto 120px;
    /* padding: 0 10px; */
  }

  .pricing h1 {
    font-size: 28px;
  }

  .pricing .priceToggler {
    height: auto;
    width: 170px;
    margin: 20px auto;
  }

  .pricing .priceToggler .menu {
    width: 100%;
    height: 45px;
    font-size: 16px;
  }

  .pricing .priceContent {
    font-size: 18px;
  }

  .pricing .priceContent .content h2 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .pricing .priceContent .content .plans-container {
    flex-direction: column;
    gap: 20px;
  }

  .pricing .priceContent .content .plans {
    width: 100%;
    padding: 20px;
  }

  .pricing .priceContent .content .plans h3 {
    font-size: 24px;
  }

  .pricing .priceContent .content .plans h4 {
    font-size: 16px;
  }

  .pricing .priceContent .content .plans p {
    font-size: 10px;
  }

  .pricing .priceContent .content .plans .price {
    text-align: right;
  }

  .pricing .priceContent .content .plans .price .discount {
    transform: translateX(130px);
    width: fit-content;
  }

  .pricing .priceContent .content .plans .discount h5 {
    font-size: 16px;
  }

  .check-icon {
    font-size: 16px;
    margin-right: 10px;
  }

  .active-badge {
    height: 26px;
    width: 100px;
    font-size: 18px;
  }
}
