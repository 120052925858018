@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Pretendard Variable', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111820;
}

code {
  font-family: 'Pretendard Variable', Consolas;
}

a, a:link, a:visited, a:hover, a:active {
  color: inherit; /* 상속 받는 색*/
  text-decoration: none;
  padding: 0;
  margin: 0;
}

button {
  padding: 0;
  margin: 0;
}