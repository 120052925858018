/* App.css */
body {
  padding: 0;
  margin: 0;
  /* background-color: #111820; */
  background: linear-gradient(#111820, #193044, #111820);
  background-attachment: fixed;
  background-size: cover;
}
.app {
  position: relative;
  /* min-height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow: hidden; */
}

.content {
  width: 100%;
  max-width: 1300px; /* Adjust as per your need */
  display: flex;
  gap: 24px; /* Adjust as per your need */
  margin-top: 24px;
  box-sizing: border-box;
}

.loading-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-content {
  text-align: center;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 4px;
}

.GoogleSDKContainer {
  display: none;
}

.custom-cursor {
  cursor: url('../public/brush-cursor.png') 16 16, pointer;
}
